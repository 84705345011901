const dataCenterList = [
	{
		name: "Aether",
		worlds: [
			"Adamantoise",
			"Cactuar",
			"Faerie",
			"Gilgamesh",
			"Jenova",
			"Midgardsormr",
			"Sargatanas",
			"Siren",
		],
	},
	{
		name: "Chaos",
		worlds: [
			"Cerberus",
			"Louisoix",
			"Moogle",
			"Omega",
			"Phantom",
			"Ragnarok",
			"Sagittarius",
			"Spriggan",
		],
	},
	{
		name: "Crystal",
		worlds: [
			"Balmung",
			"Brynhildr",
			"Coeurl",
			"Diabolos",
			"Goblin",
			"Malboro",
			"Mateus",
			"Zalera",
		],
	},
	{
		name: "Dynamis",
		worlds: [
			"Halicarnassus",
			"Maduin",
			"Marilith",
			"Seraph",
			"Kraken",
			"Cuchulainn",
			"Golem",
			"Rafflesia",
		],
	},
	{
		name: "Elemental",
		worlds: [
			"Aegis",
			"Atomos",
			"Carbuncle",
			"Garuda",
			"Gungnir",
			"Kujata",
			"Tonberry",
			"Typhon",
		],
	},
	{
		name: "Gaia",
		worlds: [
			"Alexander",
			"Bahamut",
			"Durandal",
			"Fenrir",
			"Ifrit",
			"Ridill",
			"Tiamat",
			"Ultima",
		],
	},
	{
		name: "Light",
		worlds: [
			"Alpha",
			"Lich",
			"Odin",
			"Phoenix",
			"Raiden",
			"Shiva",
			"Twintania",
			"Zodiark",
		],
	},
	{
		name: "Mana",
		worlds: [
			"Anima",
			"Asura",
			"Chocobo",
			"Hades",
			"Ixion",
			"Masamune",
			"Pandaemonium",
			"Titan",
		],
	},
	{
		name: "Materia",
		worlds: ["Bismarck", "Ravana", "Sephirot", "Sophia", "Zurvan"],
	},
	{
		name: "Meteor",
		worlds: [
			"Belias",
			"Mandragora",
			"Ramuh",
			"Shinryu",
			"Unicorn",
			"Valefor",
			"Yojimbo",
			"Zeromus",
		],
	},
	{
		name: "Primal",
		worlds: [
			"Behemoth",
			"Excalibur",
			"Exodus",
			"Famfrit",
			"Hyperion",
			"Lamia",
			"Leviathan",
			"Ultros",
		],
	},
];

export default dataCenterList;
