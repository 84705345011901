const chatsList = [
	{ value: "/cwl1", name: "CWLS1" },
	{ value: "/cwl2", name: "CWLS2" },
	{ value: "/cwl3", name: "CWLS3" },
	{ value: "/cwl4", name: "CWLS4" },
	{ value: "/cwl5", name: "CWLS5" },
	{ value: "/cwl6", name: "CWLS6" },
	{ value: "/cwl7", name: "CWLS7" },
	{ value: "/cwl8", name: "CWLS8" },
	{ value: "/l1", name: "LS1" },
	{ value: "/l2", name: "LS2" },
	{ value: "/l3", name: "LS3" },
	{ value: "/l4", name: "LS4" },
	{ value: "/l5", name: "LS5" },
	{ value: "/l6", name: "LS6" },
	{ value: "/l7", name: "LS7" },
	{ value: "/l8", name: "LS8" },
	{ value: "/say", name: "Say" },
	{ value: "/y", name: "Yell" },
	{ value: "/sh", name: "Shout" },
	{ value: "/party", name: "Party" },
	{ value: "/fc", name: "Free Company" },
	{ value: "/n", name: "Novice Network" },
];

export default chatsList;
