import "./App.css";
import { useEffect, useState } from "react";
import dataCenterList from "./Data/datacenters";
import chatsList from "./Data/chats";

function App() {
	const [dataCenter, setDataCenter] = useState(
		JSON.parse(localStorage.getItem("dataCenter")) || 0
	);
	const [world, setWorld] = useState(
		JSON.parse(localStorage.getItem("world")) || 0
	);
	const [chats, setChats] = useState([]);
	const [template, setTemplate] = useState(
		localStorage.getItem("template") ||
			"A Rank Hunt Train on $w starting at <flag>!"
	);
	const [macroText, setMacroText] = useState("");

	useEffect(() => {
		if (
			localStorage.getItem("chats") !== null &&
			localStorage.getItem("chats").length > 0
		) {
			setChats(localStorage.getItem("chats").split(","));
		}

		console.log("if you see this you owe me 100k gil in game");
	}, []);

	useEffect(() => {
		// macro output is dependent on: world, DC, template, and chats changing.
		let worldPlaceholder = 0;
		let dataCenterPlaceholder = 0;
		!world ? (worldPlaceholder = 0) : (worldPlaceholder = world);
		!dataCenter
			? (dataCenterPlaceholder = 0)
			: (dataCenterPlaceholder = dataCenter);
		const templatedText = template.replace(
			"$w",
			dataCenterList[dataCenterPlaceholder].worlds[worldPlaceholder]
		);
		let newMacroOutput = [];
		if (chats) {
			chats.forEach((i) => {
				// Add the chat slash command and then templated text
				newMacroOutput.push(`${i} ${templatedText}`);
			});
		}
		setMacroText(newMacroOutput.join("\r\n"));

		// Update local storage ->
		localStorage.setItem("dataCenter", dataCenter);
		localStorage.setItem("world", world);
		localStorage.setItem("template", template);
		localStorage.setItem("chats", chats);
	}, [world, dataCenter, template, chats]);

	function handleChatToggle(e) {
		const newArray = chats.concat();
		if (!chats.includes(e.target.value)) {
			newArray.push(e.target.value);
		} else {
			// Otherwise, it already exists and we should splice it out.
			newArray.splice(newArray.indexOf(e.target.value), 1);
		}
		setChats(newArray);
	}

	return (
		<div className="App">
			<div className="inputs">
				<h1>Relay Macro Generator</h1>
				{/* SELECT DATA CENTER */}
				<h2>Select DC and World</h2>
				<div className="selects">
					<select
						name="datacenter"
						id="datacenter"
						onChange={(e) => {
							setDataCenter(e.target.value);
							setWorld(0);
						}}
						value={dataCenter}
					>
						{dataCenterList.map((i, index) => {
							return (
								<option
									key={`datacenter-${index}`}
									value={index}
								>
									{i.name}
								</option>
							);
						})}
					</select>
					{/* SELECT WORLD */}
					<select
						name="world"
						id="world"
						onChange={(e) => {
							setWorld(e.target.value);
						}}
						value={world}
					>
						{dataCenterList[dataCenter].worlds.map(
							(i, index) => {
								return (
									<option
										key={`world-${index}`}
										value={index}
									>
										{i}
									</option>
								);
							}
						)}
					</select>
				</div>
				{/* TEXT AREA FOR TEMPLATE */}
				<h2>Set a Base Template</h2>
				<textarea
					name="macro-template"
					id="macro-template"
					value={template}
					row="5"
					cols="50"
					onChange={(e) => {
						setTemplate(e.target.value);
					}}
				></textarea>
				<p className="copy">
					<strong>
						<em>
							$w is where the world name will go --
							only the first instance of it will be
							replaced.
						</em>
					</strong>{" "}
					You can also use special symbols{" "}
					<a href="https://na.finalfantasyxiv.com/lodestone/character/22423564/blog/4393835">
						(such as block text)
					</a>
					, but you won't be able to see them in your
					browser while editing. They will, however, show up
					in-game.
				</p>
				<h2>Choose the Relay Channels</h2>
				{/* SELECT CHATS */}
				<div className="chatsList">
					{chatsList.map((i, index) => {
						return (
							<div>
								{chats.indexOf(i.value) >
									-1 && (
									<input
										type="checkbox"
										id={`chat-${index}`}
										onChange={
											handleChatToggle
										}
										value={i.value}
										checked
									/>
								)}
								{chats.indexOf(i.value) ===
									-1 && (
									<input
										type="checkbox"
										id={`chat-${index}`}
										onChange={
											handleChatToggle
										}
										value={i.value}
									/>
								)}
								<label
									htmlFor={`chat-${index}`}
								>
									{i.name}
								</label>
							</div>
						);
					})}
				</div>
			</div>
			<div className="output">
				<h2>Output</h2>
				<p className="copy">
					You might see some CRLF related issues (when you
					paste into user macros in game, it copies all into
					one line) - that is typically the case on some
					OSes/some browsers. If that's the case for you,
					try ctrl+c and ctrl+v into the game instead of
					using the button, or try using a different
					browser.
				</p>
				<textarea
					id="output-macro"
					disabled
					cols="50"
					value={macroText}
				></textarea>{" "}
				<button
					onClick={() => {
						navigator.clipboard.writeText(macroText);
					}}
				>
					copy
				</button>
				<p className="copy">
					Made by Lychee Boba of Seraph. Reach out directly
					in-game or via the Faloop Discord for issues or
					updates, maybe feature requests if I feel like it.
					FFXIV, Final Fantasy, and all Data Center/World
					names are registered trademark of SquareEnix.
				</p>
			</div>
		</div>
	);
}

export default App;
